export default {
  toBeDefined: {
    limitSluUSD: '',
    premiumSluUSD: '',
    limitedInsuredUSD: '',
    limitSlu: '',
    sluShare: '',
    premiumSlu: '',
    limitedInsured: '',
  },
};
