export default {
  quotation: {
    insuredName: '',
    currency: '',
    exchangeRate: null,
    inceptionDate: '',
    expiryDate: '',
    typeQuotation: 1,
  },
  deductions: {
    brokerage: null,
    fronting: null,
    eng: null,
    taxes: null,
    premiumReserve: null,
    lta: null,
    others: null,
    deductionType: 1,
    sum: null,
  },
  tiv: {
    propertyDamage: null,
    businessInterruption: null,
    stock: null,
    total: null,
    totalUSD: null,
    porcentaje: '',
    propertyDamageUSD: null,
    businessInterruptionUSD: null,
    stockUSD: null,
  },
  premium: {
    propertyDamage: null,
    businessInterruption: null,
    stock: null,
    propertyDamageRate: null,
    businessInterruptionRate: null,
    stockRate: null,
    totalInsured: null,
    totalUSD: null,
    porcentaje: '',
    propertyDamageUSD: null,
    businessInterruptionUSD: null,
    stockUSD: null,
  },
  tivNon: {
    propertyDamage: null,
    businessInterruption: null,
    stock: null,
    propertyDamageUSD: null,
    businessInterruptionUSD: null,
    stockUSD: null,
    total: null,
    totalUSD: null,
  },
  premiumNon: {
    rate: null,
    rateUSD: null,
    pml: null,
    pmlUSD: null,
    totalPremium: null,
    totalPremiumUSD: null,
  },
  calculatePML: [],
  layersArray: [{ id: 1, layer: '', limit: null, excess: null, deductions: null, sluShare: null, premium: 0, sluLimit: null, sluPremium: null }],
  layerSelect: null,

  //static
  smallestRisks: [
    0.0,
    1.9,
    3.7,
    5.5,
    7.1,
    8.8,
    10.4,
    11.9,
    13.4,
    14.8,
    16.3,
    17.6,
    19.0,
    20.3,
    21.6,
    22.9,
    24.1,
    25.4,
    26.6,
    27.8,
    28.9,
    30.1,
    31.2,
    32.3,
    33.4,
    34.5,
    35.6,
    36.7,
    37.7,
    38.8,
    39.8,
    40.8,
    41.8,
    42.8,
    43.8,
    44.8,
    45.8,
    46.8,
    47.7,
    48.7,
    49.6,
    50.6,
    51.5,
    52.4,
    53.4,
    54.3,
    55.2,
    56.1,
    57.0,
    57.9,
    58.8,
    59.7,
    60.6,
    61.4,
    62.3,
    63.2,
    64.1,
    64.9,
    65.8,
    66.6,
    67.5,
    68.3,
    69.2,
    70.0,
    70.9,
    71.7,
    72.6,
    73.4,
    74.2,
    75.1,
    75.9,
    76.7,
    77.5,
    78.4,
    79.2,
    80.0,
    80.8,
    81.6,
    82.5,
    83.3,
    84.1,
    84.9,
    85.7,
    86.5,
    87.3,
    88.1,
    88.9,
    89.7,
    90.5,
    91.3,
    92.1,
    92.9,
    93.7,
    94.5,
    95.3,
    96.1,
    96.8,
    97.6,
    98.4,
    99.2,
    100.0,
  ],
  smallHouses: [
    0.0,
    4.1,
    7.7,
    10.8,
    13.7,
    16.2,
    18.6,
    20.8,
    22.9,
    24.8,
    26.7,
    28.4,
    30.0,
    31.6,
    33.1,
    34.6,
    36.0,
    37.3,
    38.6,
    39.9,
    41.1,
    42.3,
    43.4,
    44.6,
    45.7,
    46.7,
    47.8,
    48.8,
    49.8,
    50.8,
    51.7,
    52.7,
    53.6,
    54.5,
    55.4,
    56.3,
    57.2,
    58.0,
    58.9,
    59.7,
    60.5,
    61.4,
    62.2,
    63.0,
    63.7,
    64.5,
    65.3,
    66.0,
    66.8,
    67.5,
    68.3,
    69.0,
    69.7,
    70.5,
    71.2,
    71.9,
    72.6,
    73.3,
    74.0,
    74.5,
    75.3,
    76.0,
    76.7,
    77.3,
    78.0,
    78.7,
    79.3,
    80.0,
    80.6,
    81.3,
    81.9,
    82.6,
    83.2,
    83.8,
    84.4,
    85.1,
    85.7,
    86.3,
    86.9,
    87.6,
    88.2,
    88.8,
    89.4,
    90.0,
    90.6,
    91.2,
    91.8,
    92.4,
    93.0,
    93.6,
    94.2,
    94.8,
    95.4,
    95.9,
    96.6,
    97.1,
    97.7,
    98.3,
    98.9,
    99.4,
    100.0,
  ],
  unMayor: [
    0.0,
    9.2,
    15.7,
    20.7,
    24.5,
    28.3,
    31.3,
    34.0,
    36.4,
    38.6,
    40.6,
    42.4,
    44.1,
    45.8,
    47.3,
    48.7,
    50.1,
    51.4,
    52.6,
    53.8,
    54.9,
    56.0,
    57.1,
    58.1,
    59.1,
    60.0,
    60.9,
    61.8,
    62.7,
    63.5,
    64.4,
    65.2,
    66.0,
    66.7,
    67.5,
    68.2,
    68.9,
    69.6,
    70.3,
    71.0,
    71.6,
    72.3,
    72.9,
    73.6,
    74.2,
    74.8,
    75.4,
    76.0,
    76.5,
    77.1,
    77.7,
    78.2,
    78.8,
    79.3,
    79.9,
    80.4,
    80.9,
    81.4,
    82.0,
    82.5,
    83.0,
    83.5,
    84.0,
    84.4,
    84.9,
    85.4,
    85.9,
    86.3,
    86.8,
    87.3,
    87.7,
    88.2,
    88.6,
    89.1,
    89.5,
    89.9,
    90.4,
    90.8,
    91.2,
    91.7,
    92.1,
    92.5,
    92.9,
    93.3,
    93.7,
    94.1,
    94.6,
    95.0,
    95.4,
    95.8,
    96.2,
    96.5,
    96.9,
    97.3,
    97.7,
    98.1,
    98.5,
    98.9,
    99.2,
    99.6,
    100.0,
  ],
  dosMayor: [
    0.0,
    17.5,
    28.1,
    34.4,
    39.1,
    42.9,
    46.1,
    48.9,
    51.3,
    53.4,
    55.4,
    57.1,
    58.7,
    60.2,
    61.6,
    62.9,
    64.1,
    65.3,
    66.4,
    67.4,
    68.4,
    69.3,
    70.2,
    71.0,
    71.9,
    72.6,
    73.4,
    74.1,
    74.8,
    75.5,
    76.2,
    76.8,
    77.4,
    78.0,
    78.6,
    79.2,
    79.7,
    80.2,
    80.8,
    81.3,
    81.8,
    82.2,
    82.7,
    83.2,
    83.6,
    84.1,
    84.5,
    84.9,
    85.3,
    85.7,
    86.1,
    86.5,
    86.9,
    87.3,
    87.7,
    88.0,
    88.4,
    88.7,
    89.1,
    89.4,
    89.7,
    90.1,
    90.4,
    90.7,
    91.0,
    91.3,
    91.6,
    91.9,
    92.2,
    92.5,
    92.8,
    93.1,
    93.4,
    93.7,
    93.9,
    94.2,
    94.5,
    94.7,
    95.0,
    95.2,
    95.5,
    95.7,
    96.0,
    96.2,
    96.5,
    96.7,
    96.9,
    97.2,
    97.4,
    97.6,
    97.9,
    98.1,
    98.3,
    98.5,
    98.7,
    99.0,
    99.2,
    99.4,
    99.6,
    99.8,
    100.0,
  ],
  tresMayor: [
    0.0,
    29.4,
    41.7,
    48.4,
    53.2,
    56.9,
    60.0,
    62.6,
    64.8,
    66.7,
    68.5,
    70.1,
    71.5,
    72.8,
    74.0,
    75.1,
    76.2,
    77.1,
    78.0,
    78.9,
    79.7,
    80.4,
    81.1,
    81.8,
    82.5,
    83.1,
    83.7,
    84.2,
    84.8,
    85.3,
    85.8,
    86.3,
    86.7,
    87.1,
    87.6,
    88.0,
    88.4,
    88.7,
    89.1,
    89.5,
    89.8,
    90.1,
    90.5,
    90.8,
    91.1,
    91.4,
    91.6,
    91.9,
    92.2,
    92.5,
    92.7,
    93.0,
    93.2,
    93.4,
    93.7,
    93.9,
    94.1,
    94.3,
    94.5,
    94.7,
    94.9,
    95.1,
    95.3,
    95.5,
    95.6,
    95.8,
    96.0,
    96.1,
    96.3,
    96.5,
    96.6,
    96.8,
    96.9,
    97.1,
    97.2,
    97.3,
    97.5,
    97.6,
    97.7,
    97.9,
    98.0,
    98.1,
    98.2,
    98.3,
    98.4,
    98.6,
    98.7,
    98.8,
    98.9,
    99.0,
    99.1,
    99.2,
    99.3,
    99.4,
    99.5,
    99.6,
    99.7,
    99.7,
    99.8,
    99.9,
    100.0,
  ],
  great: [
    0.0,
    42.6,
    53.3,
    60.0,
    64.5,
    68.0,
    70.9,
    73.2,
    75.2,
    77.0,
    78.5,
    79.9,
    81.1,
    82.2,
    83.2,
    84.2,
    85.0,
    85.8,
    86.5,
    87.2,
    87.9,
    88.4,
    89.0,
    89.5,
    90.0,
    90.5,
    90.9,
    91.3,
    91.7,
    92.1,
    92.4,
    92.8,
    93.1,
    93.4,
    93.7,
    93.9,
    94.2,
    94.4,
    94.7,
    94.9,
    95.1,
    95.3,
    95.5,
    95.7,
    95.9,
    96.1,
    96.2,
    96.4,
    96.6,
    96.7,
    96.8,
    97.0,
    97.1,
    97.2,
    97.4,
    97.5,
    97.6,
    97.7,
    97.8,
    97.9,
    98.0,
    98.1,
    98.2,
    98.3,
    98.3,
    98.4,
    98.5,
    98.6,
    98.6,
    98.7,
    98.8,
    98.8,
    98.9,
    99.0,
    99.0,
    99.1,
    99.1,
    99.2,
    99.2,
    99.3,
    99.3,
    99.4,
    99.4,
    99.5,
    99.5,
    99.5,
    99.6,
    99.6,
    99.7,
    99.7,
    99.7,
    99.8,
    99.8,
    99.8,
    99.8,
    99.9,
    99.9,
    99.9,
    100.0,
    100.0,
    100.0,
  ],
};
