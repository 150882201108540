/* api */
import { apolloClient } from "../../lib/api";

/* mutaciones */
/* ... */

/* queries */
import GET_CONTACTS_QUERY from "./queries/getAllContacts";
import GET_CONTACTS_BY_QUERY from "./queries/getContactsBy";

/* constantes */
import messages from "../../constants/messages";

export default {
  async loadContacts({ commit }) {
    try {
      const { data } = await apolloClient.query({
        query: GET_CONTACTS_QUERY,
        fetchPolicy: "no-cache",
      });

      const { statusCode, response } = data.getAllContacts;
      if (statusCode !== 200) throw new Error("Load Contacts Error");

      const parsedResponse = JSON.parse(response);
      commit("SET_CONTACTS", parsedResponse);
    } catch ({ message }) {
      const messageToDisplay = message.replace("GraphQL error: ", "");
      commit("addNotification", {
        type: messages.DANGER,
        text: messageToDisplay,
      });
    }
  },
  async filterContactsByType({ commit }, { contact, query = '' }) {
    try {
      /**
       * type 1 - broker
       * type 2 - cedent
       * brokerOrCedent debe ser un array para el where del query
       */
      const variables = { contact, ...(query && { query }) };
      const { data: { getContactsBy } } = await apolloClient.query({
        query: GET_CONTACTS_BY_QUERY,
        variables,
        fetchPolicy: "no-cache",
      });

      const { statusCode, response } = getContactsBy;
      if (statusCode !== 200) throw new Error("Error while filtering contacts");

      const parsedResponse = JSON.parse(response);
      commit("SET_CONTACTS", parsedResponse);
    } catch ({ message }) {
      const messageToDisplay = message.replace("GraphQL error: ", "");
      commit("addNotification", {
        type: messages.DANGER,
        text: messageToDisplay,
      });
    }
  },
};
