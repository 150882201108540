export default {
  cedentData: {
    cedentName: '',
    cedentEmail: '',
    cedentPhone: '',
    cedentAddress: '',
    cedentCountry: '',
    cedentCompany: '',
    cedentRole: '',
  },
  submittedCedentData: false,
  availableCedents: [],
}