/* api */
import { apolloClient } from '../../lib/api';

/* mutaciones */
import COPY_SUBSCRIPTION_MUTATION from './mutations/copySubscription';

/* queries */
import FIND_QUOTATION_QUERY from './queries/findQuotationBySubscription';

/* constantes */
import messages from '../../constants/messages';
import QUOTATION from '../../constants/quotation';

/* utils */
import { toSnakeCase, keysToCamel } from './utils';

/* store */
import $router from '../../router';

export default {
  async createCopyAccount({ commit, state }, view) {
    try {
      const views = {
        quotation: '',
        submission: '',
      };
      const variables = { subscriptionId: state.subscription_id };
      const { data } = await apolloClient.mutate({
        mutation: COPY_SUBSCRIPTION_MUTATION,
        variables,
        fetchPolicy: 'no-cache',
      });

      const { statusCode, response } = data['copySubscription'];
      if (statusCode !== 200) throw new Error('Error Copy Account');

      const parsedResponse = JSON.parse(response);

      $router.push({
        name: 'Edit Subs submission',
        params: { subscriptionId: parsedResponse.submission.subscription_id }
      });

      commit('addNotification', {
        type: messages.SUCCESS,
        text: 'Copied Account!',
      });
    } catch ({ message }) {
      const messageToDisplay = message.replace('GraphQL error: ', '');
      commit('addNotification', {
        type: messages.DANGER,
        text: messageToDisplay,
      });
    }
  },
};
