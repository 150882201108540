import state from "../state"
import VueJwtDecode from 'vue-jwt-decode'
export default {
  LOGIN_USER(state, user) {
    state.auth.authStatus = true
    state.auth.user = { ...user }
  },

  SET_TOKEN(state, token) {
    const user = VueJwtDecode.decode(token);
    state.auth.user = {
      ...state.auth.user,
      name: user.name,
      email: user.email,
      lastName: user.lastName,
      token
    }
  },

  setUsersList(state, users) {
    state.auth.list = users.users
  },

  setActivationSuccess(state) {
    state.auth.activationOk = true;
  },

  setTokenOutlook(state, token) {
    if (token !== null && token !== '') {
      state.auth.isSingingSuccesOutlook = true
    }
    state.auth.tokenOutlook = token
  },
  LOGOUT_USER(state) {
    state.auth.authStatus = ''
    state.auth.tokenOutlook = ''
    state.auth.isSingingSuccesOutlook = false;
    localStorage.removeItem('tokenOutlook')
    state.auth.token = '' && localStorage.removeItem('sessionToken')
  }
}