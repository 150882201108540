import { apolloClient } from '../../lib/api';
import saveNoteMutation from './mutations/saveNote';
import deleteNoteMutation from './mutations/deleteNote';
import messages from '../../constants/messages';
import notesSubscriptionQuery from './queries/getNotes';

export default {
  /* (context) destructured */
  async saveNote({ commit, dispatch }, { description, subscription_id }) {
    try {
      const {
        data: { addNote },
      } = await apolloClient.mutate({
        mutation: saveNoteMutation,
        variables: {
          note: {
            description: description,
            subscription_id: subscription_id,
            parent_note_id: 0,
          },
        },
        fetchPolicy: 'no-cache',
      });
      const { statusCode, message, response } = addNote;
      if (statusCode != 200) throw new Error(`Failed send note: ${message}`);
      dispatch('noteSubscription', { subscription_id });
      return 'success';
    } catch ({ message }) {
      const messageToDisplay = message.replace('GraphQL error: ', '');
      commit('addNotification', {
        type: messages.DANGER,
        text: messageToDisplay,
      });
    }
  },
  async deleteNote({ commit, dispatch }, idnote) {
    try {
      const {
        data: { delNote },
      } = await apolloClient.mutate({
        mutation: deleteNoteMutation,
        variables: {
          idnote,
        },
        fetchPolicy: 'no-cache',
      });
      const { statusCode, message, response } = delNote;
      if (statusCode != 200) throw new Error(`Failed upload document file: ${message}`);
    } catch ({ message }) {
      const messageToDisplay = message.replace('GraphQL error: ', '');
      commit('addNotification', {
        type: messages.DANGER,
        text: messageToDisplay,
      });
    }
  },
  async noteSubscription({ commit, dispatch }, { subscription_id }) {
    try {
      const {
        data: { getNotesSubscription },
      } = await apolloClient.query({
        query: notesSubscriptionQuery,
        variables: {
          subscription_id,
        },
        fetchPolicy: 'no-cache',
      });
      const { statusCode, message, response } = getNotesSubscription;
      if (statusCode != 200) throw new Error(`Failed fetching data: ${message}`);
      const parsedResponse = JSON.parse(response);
      var hideNotes = [];
      parsedResponse.forEach((value, index) => {
        value.showRes = false;
        var date = value.created_at.split('T', 2);
        value.date = date[0];
        value.arrayResponse = [];
        var result = parsedResponse.find((item) => item.id === value.parent_note_id);
        if (result) {
          result.arrayResponse.push(value);
          var i = parsedResponse.indexOf(value);
          if (i !== -1) {
            hideNotes.push(i);
          }
        }
      });
      for (var i = hideNotes.length - 1; i >= 0; i--) {
        parsedResponse.splice(hideNotes[i], 1);
      }
      commit('setArrayNotes', parsedResponse);
    } catch ({ message }) {
      const messageToDisplay = message.replace('GraphQL error: ', '');
      commit('addNotification', {
        type: messages.DANGER,
        text: messageToDisplay,
      });
    }
  },
  async responseNote({ commit, dispatch }, { description, subscription_id, parent_note_id }) {
    try {
      const {
        data: { addNote },
      } = await apolloClient.mutate({
        mutation: saveNoteMutation,
        variables: {
          note: {
            description: description,
            subscription_id: subscription_id,
            parent_note_id: parent_note_id,
          },
        },
        fetchPolicy: 'no-cache',
      });
      const { statusCode, message, response } = addNote;
      if (statusCode != 200) throw new Error(`Failed send note: ${message}`);
      dispatch('noteSubscription', { subscription_id });
      return 'success';
    } catch ({ message }) {
      const messageToDisplay = message.replace('GraphQL error: ', '');
      commit('addNotification', {
        type: messages.DANGER,
        text: messageToDisplay,
      });
    }
  },
};
