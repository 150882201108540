export default {
  submittedAccountInformation: false,
  accountInformation: {
    insuredName: '',
    name: '',
    country: '',
    typeOfRisk: '',
    activity: '',
    currency: '',
    broker: '',
    cedent: '',
  }
}