import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';

import Vuelidate from 'vuelidate';
Vue.use(Vuelidate);

//Import global styles
import '@/assets/global.less';
import '@/assets/fonts/Fonts.less';

import { createProvider } from '@/lib/api';

/* Vue Config & Providers */
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  apolloProvider: createProvider(),
  render: (h) => h(App),
}).$mount('#app');
