export default {
  SET_CURRENT_EMAIL_TEMPLATE(state, { currentEmailTemplate }) {
    state.currentEmailTemplate = currentEmailTemplate;
  },
  SET_CURRENT_EMAIL_RISK_ID(state, { id }) {
    state.currentTemplateRiskID = id;
  },
  SET_SELECTED_TEMPLATE_LANG(state, lang) {
    state.selectedLang = lang;
  },
  RESET_SEND_EMAIL(state) {
    state.currentEmailTemplate = "";
  },
};
