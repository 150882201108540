import store from '../store';
import { RouteConfig } from 'vue-router';

export const subscription = [
  //HOME
  {
    path: '/subscription/',
    name: 'Subs home',
    component: () => import('../views/Subscription/Home.vue'),
    meta: { requiresAuth: true },
  },
  // SUBMISSION
  {
    path: '/subscription/submission',
    name: 'Subs submission',
    component: () => import('../views/Subscription/Submission.vue'),
    meta: { requiresAuth: true },
  },
  /* trabajando en ello */
  {
    path: '/subscription/:subscriptionId/submission',
    name: 'Edit Subs submission',
    component: () => import('../views/Subscription/Submission.vue'),
    meta: { requiresAuth: true },
    async beforeEnter(to, from, next) {
      if (to.params && to.params.subscriptionId) {
        const id = to.params.subscriptionId;
        const valid = await validateSubscriptionRoute(id);
        if (valid) {
          next();
        } else {
          next('/dashboard');
        }
      } else {
        next();
      }
    },
  },
  {
    path: '/subscription/quotation/non-proportional',
    name: 'Quotation non proportional',
    component: () => import('../views/Subscription/QuotationNonProportional.vue'),
    meta: { requiresAuth: true },
  },

  {
    path: '/subscription/quotation/proportional',
    name: 'Quotation proportional',
    component: () => import('../views/Subscription/QuotationProportional.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/subscription/:subscriptionId/quotation/proportional',
    name: 'Edit Subs quotation',
    component: () => import('../views/Subscription/QuotationProportional.vue'),
    meta: { requiresAuth: true },
    async beforeEnter(to, from, next) {
      if (to.params && to.params.subscriptionId) {
        const id = to.params.subscriptionId;
        const valid = await validateSubscriptionRoute(id);
        if (valid) {
          next();
        } else {
          next('/dashboard');
        }
      } else {
        next();
      }
    },
  },
  {
    path: '/subscription/:subscriptionId/quotation/non-proportional',
    name: 'Edit Subs quotation non-proportional',
    component: () => import('../views/Subscription/QuotationNonProportional.vue'),
    meta: { requiresAuth: true },
    async beforeEnter(to, from, next) {
      if (to.params && to.params.subscriptionId) {
        const id = to.params.subscriptionId;
        const valid = await validateSubscriptionRoute(id);
        if (valid) {
          next();
        } else {
          next('/dashboard');
        }
      } else {
        next();
      }
    },
  },
  //NOTES
  {
    path: '/notes',
    name: 'Notes',
    component: () => import('../views/Subscription/Notes.vue'),
    meta: { requiresAuth: true },
  },
  //Bound
  {
    path: '/subscription/bound',
    name: 'Bound',
    component: () => import('../views/Subscription/Bound.vue'),
    meta: { requiresAuth: true },
  },
];

const validateSubscriptionRoute = async (id) => {
  const exists = await store.dispatch('existsSubscription', { id });
  return exists;
};
