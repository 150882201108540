import auth from "./auth";
import docs from "./docs";
import ui from "./ui";
import errors from "./errors";
import emails from "./emails";
import subscription from "./subscription";
import createBroker from "./brokersAndCedents";
import notes from "./notes";
import users from "./users";
import companies from "./companies";
import contacts from "./contacts";
import quotation from "./quotation";
import copy from './copyData';

export default {
  ...auth,
  ...ui,
  ...docs,
  ...errors,
  ...emails,
  ...subscription,
  ...createBroker,
  ...notes,
  ...users,
  ...companies,
  ...contacts,
  ...quotation,
  ...copy,
};
