import { apolloClient } from '../../lib/api';
import uploadFile from './mutations/uploadFile';
import deleteFile from './mutations/deleteFile';
import saveDocumentMutation from './mutations/saveDocument';
import catalogQueryDocuments from './queries/getCatalogDocuments';
import messages from '../../constants/messages';
import getDocumentsSubscriptionRiskQuery from './queries/getDocumentsSubscriptionRisk';
import getSlipDocumentsSubscriptionQuery from './queries/getSlipDocumentsSubscription';
import getCorrespondenceDocumentsSubscriptionQuery from './queries/getCorrespondenceDocumentsSubscription';
import getDownloadDocQuery from './queries/getDownloadDoc';

export default {
  /* (context) destructured */
  async upload({ commit, dispatch }, { file, path }) {
    try {
      const { data } = await apolloClient.mutate({
        mutation: uploadFile,
        variables: {
          file,
          path,
        },
        fetchPolicy: 'no-cache',
      });
      return data;
    } catch (e) {
      console.error(e);
      return { error: true };
    }
  },
  async delete({ commit, dispatch }, documentName) {
    try {
      const {
        data: { singleDelete },
      } = await apolloClient.mutate({
        mutation: deleteFile,
        variables: {
          documentName: documentName,
        },
        fetchPolicy: 'no-cache',
      });
      return singleDelete;
    } catch (e) {
      const messageToDisplay = 'GraphQL error: ';
      commit('addNotification', {
        type: messages.DANGER,
        text: messageToDisplay,
      });
    }
  },

  async save({ commit, dispatch }, { document_id, subscription_id, type }) {
    try {
      const {
        data: { saveDocument },
      } = await apolloClient.mutate({
        mutation: saveDocumentMutation,
        variables: {
          document: {
            document_id: document_id,
            type: type,
            subscription_id: subscription_id,
          },
        },
        fetchPolicy: 'no-cache',
      });
      const { statusCode, message, response } = saveDocument;
      if (statusCode != 200) throw new Error(`Failed upload document file: ${message}`);
      const parsedResponse = JSON.parse(response);
      const doc_s3 = parsedResponse.doc_s3;
      commit('set_upload_document', { document: doc_s3 });
    } catch ({ message }) {
      const messageToDisplay = message.replace('GraphQL error: ', '');
      commit('addNotification', {
        type: messages.DANGER,
        text: messageToDisplay,
      });
    }
  },

  async getCatalogByDocuments({ commit, dispatch }, { name }) {
    try {
      const {
        data: { getCatalogDocuments },
      } = await apolloClient.query({
        query: catalogQueryDocuments,
        fetchPolicy: 'no-cache',
      });
      const { statusCode, message, response } = getCatalogDocuments;
      if (statusCode != 200) throw new Error(`Failed fetching documents data: ${message}`);

      commit('setCatalogByName', { name, response });
    } catch ({ message }) {
      const messageToDisplay = message.replace('GraphQL error: ', '');
      commit('addNotification', {
        type: messages.DANGER,
        text: messageToDisplay,
      });
    }
  },
  setSlipDocument({ commit }, document) {
    commit('set_slip_document', document);
  },
  setCorrespondenceDocument({ commit }, document) {
    commit('set_correspondence_document', document);
  },

  downloadDocument({ commit }, value) {
    window.open(value, '_blank').focus();
  },

  async DocumentsSubscriptionRisk({ commit, dispatch }, { subscription_id, documents, typeOfRisk }) {
    const docs = [];
    const newDocs = [];
    documents.forEach((item) => {
      if (item.idrisk === typeOfRisk) {
        newDocs.push(item);
        docs.push(item.id);
      }
    });
    commit('setDocuments', newDocs);
    try {
      const {
        data: { getDocumentsSubscriptionRisk },
      } = await apolloClient.query({
        query: getDocumentsSubscriptionRiskQuery,
        variables: {
          subscription_id,
          docs,
        },
        fetchPolicy: 'no-cache',
      });
      const { statusCode, message, response } = getDocumentsSubscriptionRisk;
      if (statusCode != 200) throw new Error(`Failed fetching data: ${message}`);
      const parsedResponse = JSON.parse(response);
      commit('setDocumentsLoader', parsedResponse);
    } catch ({ message }) {
      const messageToDisplay = message.replace('GraphQL error: ', '');
      commit('addNotification', {
        type: messages.DANGER,
        text: messageToDisplay,
      });
    }
  },

  async SlipDocumentsSubscription({ commit, dispatch }, { subscription_id }) {
    try {
      const {
        data: { getSlipDocumentsSubscription },
      } = await apolloClient.query({
        query: getSlipDocumentsSubscriptionQuery,
        variables: {
          subscription_id,
        },
        fetchPolicy: 'no-cache',
      });
      const { statusCode, message, response } = getSlipDocumentsSubscription;
      if (statusCode != 200) throw new Error(`Failed fetching data: ${message}`);
      const parsedResponse = JSON.parse(response);
      commit('setSlipDocuments', parsedResponse);
    } catch ({ message }) {
      const messageToDisplay = message.replace('GraphQL error: ', '');
      commit('addNotification', {
        type: messages.DANGER,
        text: messageToDisplay,
      });
    }
  },

  async CorrespondenceDocumentsSubscription({ commit, dispatch }, { subscription_id }) {
    try {
      const {
        data: { getCorrespondenceDocumentsSubscription },
      } = await apolloClient.query({
        query: getCorrespondenceDocumentsSubscriptionQuery,
        variables: {
          subscription_id,
        },
        fetchPolicy: 'no-cache',
      });
      const { statusCode, message, response } = getCorrespondenceDocumentsSubscription;
      if (statusCode != 200) throw new Error(`Failed fetching data: ${message}`);
      const parsedResponse = JSON.parse(response);
      commit('setCorrespondenceDocuments', parsedResponse);
    } catch ({ message }) {
      const messageToDisplay = message.replace('GraphQL error: ', '');
      commit('addNotification', {
        type: messages.DANGER,
        text: messageToDisplay,
      });
    }
  },

  async DownloadDoc({ commit, dispatch }, { path }) {
    try {
      const {
        data: { getDownloadDoc },
      } = await apolloClient.query({
        query: getDownloadDocQuery,
        variables: {
          path,
        },
        fetchPolicy: 'no-cache',
      });
      const { statusCode, message, response } = getDownloadDoc;
      if (statusCode != 200) throw new Error(`Failed fetching data: ${message}`);
      console.log(response);
      //const parsedResponse = JSON.parse(response);
      commit('setDownloadDocUrl', response);
    } catch ({ message }) {
      const messageToDisplay = message.replace('GraphQL error: ', '');
      commit('addNotification', {
        type: messages.DANGER,
        text: messageToDisplay,
      });
    }
  },
};
