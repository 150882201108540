export default {
  type: ({ type }) => type, // new or renewal
  countries: ({ countries }) => {
    if (countries.length === 0) return [];
    return countries;
  },
  risk_type: ({ risk_type }) => {
    if (risk_type.length === 0) return [];
    return risk_type;
  },
  activities: ({ activities }) => {
    if (activities.length === 0) return [];
    return activities;
  },
  currencies: ({ currencies }) => {
    if (currencies.length === 0) return [];
    return currencies;
  },
  activitiesById: ({ activities }) => (id) => {
    if (typeof id == 'undefined' || !id) return [];
    return activities.filter(({ risk_type_id }) => risk_type_id == id);
  },
  subscription_id: ({ subscription_id }) => subscription_id,
  subscriptionStatus: ({ subscriptionStatus }) => subscriptionStatus,
  nameReference: ({ nameReference }) => nameReference,
  lastSubscriptionId: ({ lastSubscriptionId }) => lastSubscriptionId,
  cedentInformation: ({ cedentInformation }) => cedentInformation,
  accountInformation: ({ accountInformation }) => accountInformation,
  contactsInformation: ({ contactsInformation }) => contactsInformation,
  submittedCedentInformation: ({ submittedCedentInformation }) => submittedCedentInformation,
  documents: ({ documents }) => documents,
  document: ({ document }) => document,
  docs: ({ docs }) => docs,
  slipDocuments: ({ slipDocuments }) => slipDocuments,
  notesArray: ({ notesArray }) => notesArray,
  correspondenceDocuments: ({ correspondenceDocuments }) => correspondenceDocuments,
  mentionsObject: ({ mentionsObject }) => mentionsObject,
  loading: ({ loading }) => loading,
  downloadDocUrl: ({ downloadDocUrl }) => downloadDocUrl,
  modalCreateCompany: ({ modalCreateCompany }) => modalCreateCompany,
  quotation: ({ quotation }) => quotation,
  deductions: ({ deductions }) => deductions,
  tiv: ({ tiv }) => tiv,
  premium: ({ premium }) => premium,
  tivNon: ({ tivNon }) => tivNon,
  premiumNon: ({ premiumNon }) => premiumNon,
  layersArray: ({ layersArray }) => layersArray,
  layerSelect: ({ layerSelect }) => layerSelect,
  smallestRisks: ({ smallestRisks }) => smallestRisks,
  smallHouses: ({ smallHouses }) => smallHouses,
  unMayor: ({ unMayor }) => unMayor,
  dosMayor: ({ dosMayor }) => dosMayor,
  tresMayor: ({ tresMayor }) => tresMayor,
  great: ({ great }) => great,
  calculatePML: ({ calculatePML }) => calculatePML,
};
