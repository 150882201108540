import accountInformation from './accountInformation';
import cedentInformation from './cedentInformation';
import dashboard from './dashboard';
import defaultOptions from './defaultOptions';
import contactsInformation from './contactsInformation';
import quotation from './quotation';

export default {
  ...contactsInformation,
  ...accountInformation,
  ...cedentInformation,
  ...defaultOptions,
  ...dashboard,
  ...quotation,
};
