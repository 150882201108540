import gql from 'graphql-tag'

export default gql`
  query getSubscriptionList($limit: Int, $offset: Int) {
    getSubscriptionList(limit: $limit, offset: $offset) {
      statusCode,
      message,
      error,
      response
    }
  }
`
