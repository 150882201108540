import gql from 'graphql-tag';

export default gql`
  mutation copySubscription($subscriptionId: ID) {
    copySubscription(subscriptionId: $subscriptionId) {
      response
      statusCode
      message
      error
    }
  }
`;
