/* api */
import { apolloClient } from "../../lib/api";

/* constantes */
import messages from "../../constants/messages";

/* mutations */
import SEND_EMAIL_MUTATION from "./mutations/sendEmail";
import ATTACH_FILE_MUTATION from "./mutations/attachFile";
import DELETE_ATTACHED_MUTATION from "./mutations/deleteAttached";
import CHANGE_TEMPLATE_LANG_MUTATION from "./mutations/changeTemplateLang";

/* query */
import EMAIL_TEMPLATE_RISK_QUERY from "./queries/getEmailTemplateByRisk";
import SUBSCRIPTION_TEMPLATE_LANG from "./queries/getSubscriptionTemplateLang";

export default {
  async getEmailTemplate({ commit, dispatch }, { id, lang = "en" }) {
    try {
      const queryInfo = {
        response: "getEmailTemplateByRisk",
        query: EMAIL_TEMPLATE_RISK_QUERY,
      };

      const variables = { riskId: id, lang };

      const { data } = await apolloClient.query({
        query: queryInfo.query,
        variables,
        fetchPolicy: "no-cache",
      });

      const { response, statusCode } = data[queryInfo.response];
      if (statusCode !== 200) throw new Error("Invalid Template");

      const parsedResponse = JSON.parse(response);
      /* set email ID & template */
      await dispatch("setEmailTemplate", {
        description: parsedResponse.description,
      });
    } catch ({ message }) {
      const messageToDisplay = message.replace("GraphQL error: ", "");
      commit("addNotification", {
        type: messages.DANGER,
        text: messageToDisplay,
      });
    }
  },
  async getCurrentTemplateLanguage({ commit, getters }) {
    try {
      const variables = {
        id: getters.subscription_id,
      };
      const {
        data: { getSubscriptionTemplateLang },
      } = await apolloClient.query({
        query: SUBSCRIPTION_TEMPLATE_LANG,
        variables,
        fetchPolicy: "no-cache",
      });

      const { statusCode, response } = getSubscriptionTemplateLang;
      if (statusCode !== 200) throw new Error("Template Lang Error");

      const parsedResponse = JSON.parse(response);
      commit("SET_SELECTED_TEMPLATE_LANG", parsedResponse.lang);
    } catch ({ message }) {
      const messageToDisplay = message.replace("GraphQL error: ", "");
      commit("addNotification", {
        type: messages.DANGER,
        text: messageToDisplay,
      });
    }
  },
  async setCurrentTemplateLanguage({ commit }, { langId, id }) {
    try {
      const variables = {
        langId,
        id,
      };
      const {
        data: { changeTemplateLang },
      } = await apolloClient.mutate({
        mutation: CHANGE_TEMPLATE_LANG_MUTATION,
        variables,
        fetchPolicy: "no-cache",
      });

      const { statusCode, response } = changeTemplateLang;
      if (statusCode !== 200) throw new Error("Lang not saved on change");

      const parsedResponse = JSON.parse(response);

      commit("SET_SELECTED_TEMPLATE_LANG", parsedResponse.lang);
    } catch ({ message }) {
      const messageToDisplay = message.replace("GraphQL error: ", "");
      commit("addNotification", {
        type: messages.DANGER,
        text: messageToDisplay,
      });
    }
  },
  async resetTemplateLang({ commit }) {
    try {
      commit("SET_SELECTED_TEMPLATE_LANG", "en");
    } catch ({ message }) {
      const messageToDisplay = message.replace("GraphQL error: ", "");
      commit("addNotification", {
        type: messages.DANGER,
        text: messageToDisplay,
      });
    }
  },
  async setEmailTemplate({ commit }, { description }) {
    try {
      commit("SET_CURRENT_EMAIL_TEMPLATE", {
        currentEmailTemplate: description,
      });
    } catch ({ message }) {
      const messageToDisplay = message.replace("GraphQL error: ", "");
      commit("addNotification", {
        type: messages.DANGER,
        text: messageToDisplay,
      });
    }
  },
  async setEmailRiskID({ commit }, { id }) {
    try {
      commit("SET_CURRENT_EMAIL_RISK_ID", { id });
    } catch ({ message }) {
      const messageToDisplay = message.replace("GraphQL error: ", "");
      commit("addNotification", {
        type: messages.DANGER,
        text: messageToDisplay,
      });
    }
  },
  async sendEmailAction({ commit, dispatch, getters }, { emailData }) {
    try {
      const variables = {
        attachedFiles: emailData.attachedFiles,
        description: emailData.description,
        cco: emailData.cco,
        cc: emailData.cc,
      };
      const {
        data: { sendEmail },
      } = await apolloClient.mutate({
        mutation: SEND_EMAIL_MUTATION,
        variables,
        fetchPolicy: "no-cache",
      });

      const { statusCode, response, message, error } = sendEmail;
      if (statusCode !== 200)
        throw new Error(`msg: ${message} error: ${error}`);

      const parsedResponse = JSON.parse(response);

      await dispatch("closeAccountAction", {
        closeAccount: {
          email: parsedResponse.id,
          id: getters.currentTemplateRiskID,
        },
      });

      await dispatch("resetEmail");

      commit("addNotification", {
        type: messages.SUCCESS,
        text: "Email Sent & Stored",
      });
    } catch ({ message }) {
      const messageToDisplay = message.replace("GraphQL error: ", "");
      commit("addNotification", {
        type: messages.DANGER,
        text: messageToDisplay,
      });
    }
  },
  async attachFile({ commit }, { file, path, name }) {
    try {
      const { data } = await apolloClient.mutate({
        mutation: ATTACH_FILE_MUTATION,
        variables: {
          file,
          path,
          name,
        },
        fetchPolicy: "no-cache",
      });

      return data.attachFile;
    } catch (e) {
      console.error(e);
      return { error: true };
    }
  },
  async resetEmail({ commit }) {
    try {
      commit("RESET_SEND_EMAIL");
    } catch ({ message }) {
      const messageToDisplay = message.replace("GraphQL error: ", "");
      commit("addNotification", {
        type: messages.DANGER,
        text: messageToDisplay,
      });
    }
  },
};
