/**
 * @typedef {Object} State
 * @property {Array} contactsInformation
 * @property {Object} accountInformation
 */
export default {
  saveAccountInformation(state, { insuredName, name, country, typeOfRisk, activity, currency, broker, cedent, contacts }) {
    state.accountInformation = {
      insuredName,
      name,
      country,
      typeOfRisk,
      activity,
      currency,
      broker,
      cedent,
      contacts,
    };
    state.submittedAccountInformation = true;
  },
  saveContactsInformation(state, data) {
    state.contactsInformation = data;
    state.submittedContactsInformation = true;
  },
  setStateAccount(state, { key, value }) {
    state.accountInformation[key] = value;
  },
  setStateCedent(state, { key, value }) {
    state.cedentInformation[key] = value;
  },
  RESET_ACCOUNT_INFORMATION(state) {
    state.accountInformation = {
      insuredName: '',
      name: '',
      country: '',
      typeOfRisk: '',
      activity: '',
      currency: '',
      broker: '',
      cedent: '',
    };
  },
  RESET_CEDENT_INFORMATION(state) {
    state.cedentInformation = {
      cedentName: '',
      cedentEmail: '',
      cedentNumber: '',
      cedentAddress: '',
    };
  },
  RESET_CONTACTS_INFORMATION(state) {
    state.contactsInformation = [];
  },
  /**
   * @param {State} state
   */
  ADD_CONTACT_INFORMATION(state, data) {
    state.contactsInformation.push(data);
  },
  /**
   * @param {State} state
   * @param {number} index
   */
  REMOVE_CONTACT_INFORMATION(state, index) {
    state.contactsInformation.splice(index, 1);
  },
  RESET_DOCUMENTS_INFORMATION(state) {
    // vacío temporalmente, en caso de ser necesario
  },
  setCatalogByName(state, { name, response }) {
    state[name] = JSON.parse(response);
  },
  NEW_OR_RENEWAL(state, { value }) {
    state.type = value;
  },
  SET_SUBSCRIPTION_INFO(state, { subscription_id }) {
    state.subscription_id = subscription_id;
  },
  SET_SUBSCRIPTION_ID(state, id) {
    state.subscription_id = id
  },
  SET_SUBSCRIPTION_STATUS(state, status) {
    state.subscriptionStatus = status;
  },
  RESET_SUBSCRIPTION_INFO(state) {
    state.subscription_id = null;
  },
  RESET_SUBSCRIPTION_STATUS(state) {
    state.subscriptionStatus = null;
  },
  SET_SUBSCRIPTION_REFERENCE(state, { nameReference }) {
    state.nameReference = nameReference;
  },
  RESET_SUBSCRIPTION_REFERENCE(state) {
    state.nameReference = '';
  },
  set_upload_document(state, { document }) {
    state.document = document;
  },
  set_slip_document(state, slipDocuments) {
    state.slipDocuments.push(slipDocuments);
  },
  set_correspondence_document(state, correspondenceDocuments) {
    state.correspondenceDocuments.push(correspondenceDocuments);
  },
  setSubscriptionList(state, payload) {
    state.subscription.table.list = JSON.parse(payload);
  },
  setSubscriptionListPagination(state, payload) {
    state.subscription.table.limit = payload.limit;
    state.subscription.table.offset = payload.offset;
  },
  addNotesArray(state, note) {
    state.notesArray.push(note);
  },
  setMentionsObject(state, mentions) {
    state.mentionsObject = mentions;
  },
  setArrayNotes(state, arrayNotes) {
    state.notesArray = arrayNotes;
  },
  setDocuments(state, arrayDocuments) {
    state.docs = arrayDocuments;
  },
  setDocumentsLoader(state, arrayDocuments) {
    arrayDocuments.forEach((item, index) => {
      state.documents.forEach((doc, num) => {
        if (doc.id == item.catalog_document_id) {
          state.docs[num] = state.documents[num];
          state.docs[num].doc_s3 = arrayDocuments[index].doc_s3;
          state.docs[num].text = arrayDocuments[index].doc_s3;
        }
      });
    });
  },
  setSlipDocuments(state, arrayDocuments) {
    if (arrayDocuments.length != 0) {
      arrayDocuments.forEach((item, index) => {
        if (index == 0) {
          item.text = 'Slip Final';
        } else {
          item.text = 'Slip Document';
        }
      });
      state.slipDocuments = arrayDocuments;
    } else {
      state.slipDocuments = [
        {
          description: 'Slip Documents',
          id: 20,
          key: 'slip',
          name: 'Slip Final',
          text: 'Upload the next document',
          value: 1,
        },
      ];
    }
  },
  setCorrespondenceDocuments(state, arrayDocuments) {
    if (arrayDocuments.length != 0) {
      arrayDocuments.forEach((item, index) => {
        item.text = 'Correspondence Document';
      });
      state.correspondenceDocuments = arrayDocuments;
    }
  },
  setLoading(state) {
    state.loading = !state.loading;
  },
  setDownloadDocUrl(state, value) {
    state.downloadDocUrl = value;
  },
  setModalCreateCompany(state) {
    state.modalCreateCompany = !state.modalCreateCompany;
  },
  setModalRate(state, type) {
    if (type == 'proportional') {
      state.modalRate = !state.modalRate;
    } else {
      state.modalRateCarEar = !state.modalRateCarEar;
    }
  },
  setModalCreateCompany(state, manualChange) {
    if (typeof manualChange === 'undefined') state.modalCreateCompany = !state.modalCreateCompany;
    else state.modalCreateCompany = manualChange;
  },
  saveQuotation(state, { insuredName, currency, exchangeRate, inceptionDate, expiryDate }) {
    state.quotation = {
      insuredName,
      currency,
      exchangeRate,
      inceptionDate,
      expiryDate,
    };
  },
  setStateQuotation(state, { key, value }) {
    state.quotation[key] = value;
  },
  setStateDeductions(state, { key, value }) {
    state.deductions[key] = value;
  },
  setStatePremium(state, { key, value }) {
    state.premium[key] = value;
  },
  setStateTobe(state, { key, value }) {
    state.toBeDefined[key] = value;
  },
  setStateCompleteQuotation(state, value) {
    state.quotation = value;
  },
  setStateCompleteDeductions(state, value) {
    state.deductions = value;
  },
  setStateCompletePremium(state, value) {
    state.premium = value;
  },
  setStateCompleteInsurable(state, value) {
    state.tiv = value;
  },
  setStateCompleteTobe(state, value) {
    state.toBeDefined = value;
  },
  setStateCompleteTIVNON(state, value) {
    state.tivNon = value;
  },
  setStateCompletePremiumNON(state, value) {
    state.premiumNon = value;
  },
  setStateTIV(state, { key, value }) {
    state.tiv[key] = parseFloat(value);
  },
  setStateTIVNON(state, { key, value }) {
    state.tivNon[key] = parseFloat(value);
  },
  setStatePremiumNON(state, { key, value }) {
    state.premiumNon[key] = parseFloat(value);
  },
  RESET_QUOTATION(state) {
    state.quotation = {
      insuredName: '',
      currency: '',
      exchangeRate: null,
      inceptionDate: '',
      expiryDate: '',
    };
  },
  setStatelayerSelect(state, value) {
    state.layerSelect = value;
  },
  resetCalculatePML(state) {
    state.calculatePML = [];
  },
};
