import auth from "./auth";
import errors from "./errors";
import subscription from "./subscription";
import email from "./email";
import create from "./create";
import close_account from "./closeAccount";
import contacts from "./contacts";
import toBeDefined from "./toBeDefined";

export default {
  ...auth,
  ...errors,
  ...subscription,
  ...email,
  ...create,
  ...close_account,
  ...contacts,
  ...toBeDefined,
};
