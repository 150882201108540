export default {
  quotation: {
    insuredName: '',
    currency: '',
    exchangeRate: null,
    inceptionDate: '',
    expiryDate: '',
    typeQuotation: 1,
  },
  deductions: {
    brokerage: null,
    fronting: null,
    eng: null,
    taxes: null,
    premiumReserve: null,
    lta: null,
    others: null,
    deductionType: 1,
  },
  tiv: {
    propertyDamage: null,
    businessInterruption: null,
    stock: null,
    total: null,
    totalUsd: null,
    porcentaje: '',
    propertyDamageUsd: null,
    businessInterruptionUsd: null,
    stockUsd: null,
  },
  premium: {
    propertyDamage: null,
    businessInterruption: null,
    stock: null,
    propertyDamageRate: null,
    businessInterruptionRate: null,
    stockRate: null,
    totalInsured: null,
    totalUsd: null,
    porcentaje: '',
    propertyDamageUsd: null,
    businessInterruptionUsd: null,
    stockUsd: null,
  },
  tivNon: {
    propertyDamage: null,
    businessInterruption: null,
    stock: null,
    propertyDamageUsd: null,
    businessInterruptionUsd: null,
    stockUsd: null,
    total: null,
    totalUsd: null,
  },
  premiumNon: {
    rate: null,
    rateUsd: null,
    pml: null,
    pmlUsd: null,
    totalPremium: null,
    totalPremiumUsd: null,
  },
  toBeDefined: {
    limitSluUsd: '',
    premiumSluUsd: '',
    limitedInsuredUsd: '',
    limitSlu: '',
    sluShare: '',
    premiumSlu: '',
    limitedInsured: '',
  },
};
