/* api */
import { apolloClient } from "../../lib/api";

/* mutaciones */
import ADD_SUBSCRIPTION from "./mutations/addSubscription";
import ADD_SUBMISSION from "./mutations/addSubmission";
import UPDATE_SUBMISSION from "./mutations/updateSubmission";
import UPDATE_SUBSCRIPTION from "./mutations/updateSubscription";
import CREATE_CLOSE_REASON from "./mutations/createCloseReason";
import UPDATE_SUBSCRIPTION_STATUS from "./mutations/updateSubscriptionStatus";

/* queries */
import catalogQuery from "./queries/getCatalog";
import subscriptionQuery from "./queries/getSubscription";
import getAllSubscriptionQuery from "./queries/getSubscriptionList";
import subscriptionExistsQuery from "./queries/subscriptionExists";
import getDocumentsSubscriptionRiskQuery from "./queries/getDocumentsSubscriptionRisk";

/* constantes */
import messages from "../../constants/messages";
import NEW_OR_RENEWAL_CONSTANT from "../../constants/newOrRenewal";

/* utils */
import {
  searchKeyInStorage,
  searchObjectInStorage,
  setItemInStorage,
  removeKeyFromStorage,
} from "./utils";

/* vue imports */
import $router from "../../router";

export default {
  /* (context) destructured */
  async subscriptionSubmission(
    { commit, dispatch, state, getters },
    { accountInformation, contactsInformation, /* documentsInformation */ type }
  ) {
    try {
      // save data on store
      commit("saveAccountInformation", accountInformation);
      commit("saveContactsInformation", contactsInformation);
      // commit('saveDocumentsInformation', documentsInformation)
      const {
        name,
        country,
        activity,
        currency,
        typeOfRisk,
        insuredName,
        broker,
        cedent,
      } = state.accountInformation;

      /* create or edit */
      const CREATE_OR_EDIT_CONFIG = {
        mutation: UPDATE_SUBMISSION,
        response: "updateSubmission",
      };

      if (!state.subscription_id || !!state.subscription_id === false) {
        CREATE_OR_EDIT_CONFIG.mutation = ADD_SUBMISSION;
        CREATE_OR_EDIT_CONFIG.response = "addSubmission";
        await dispatch("registerIdSubscription");
      }

      const variables = {
        insuredName,
        catalogTypeRiskId: typeOfRisk.id,
        name,
        type,
        catalogActivityId: activity.id,
        catalogCountryId: country.id,
        catalogCurrencyId: currency.id,
        broker: broker || null,
        cedent,
        contacts: state.contactsInformation || null,
        subscriptionId: state.subscription_id,
      };

      const { data } = await apolloClient.mutate({
        mutation: CREATE_OR_EDIT_CONFIG.mutation,
        variables,
        fetchPolicy: "no-cache",
      });

      /* create or update key - addSubmission | updateSubmission */
      const key = CREATE_OR_EDIT_CONFIG.response;
      const responseName = data[key];

      const { statusCode, response, message, error } = responseName;
      if (statusCode !== 200)
        throw new Error(`Account Information Save Error: ${message}, ${error}`);

      const parsedResponse = JSON.parse(response);

      if ("subscriptionId" in parsedResponse) {
        const { subscriptionId } = parsedResponse;
        commit("SET_SUBSCRIPTION_INFO", { subscription_id: subscriptionId });
        setItemInStorage("subscriptionData", "subscriptionId", subscriptionId);
      } else {
        const { subscription_id } = JSON.parse(response);
        commit("SET_SUBSCRIPTION_INFO", { subscription_id });
        setItemInStorage("subscriptionData", "subscriptionId", subscription_id);
      }

      console.log(parsedResponse);

      commit("saveAccountInformation", {
        insuredName: parsedResponse.insured_name,
        name: parsedResponse.name,
        country: parsedResponse.catalog_country_id,
        typeOfRisk: parsedResponse.catalog_type_risk_id,
        activity: parsedResponse.catalog_activity_id,
        currency: parsedResponse.catalog_currency_id,
        broker: parsedResponse.broker,
        cedent: parsedResponse.cedent,
      });

      commit("saveContactsInformation", parsedResponse.contacts || []);

      await dispatch("newOrRenewal", { value: parsedResponse.type });
    } catch ({ message }) {
      commit("RESET_ACCOUNT_INFORMATION");
      commit("RESET_CONTACTS_INFORMATION");
      const messageToDisplay = message.replace("GraphQL error: ", "");
      commit("addNotification", {
        type: messages.DANGER,
        text: messageToDisplay,
      });
    }
  },
  async updateSubscriptionStatus({ commit, state }, newStatus) {
    try {
      const variables = {
        subscriptionId: state.subscription_id,
        statusId: newStatus,
      };
      const { data } = await apolloClient.mutate({
        mutation: UPDATE_SUBSCRIPTION_STATUS,
        variables,
        fetchPolicy: "no-cache",
      });

      const { statusCode, response } = data.updateSubscriptionStatus;
      if (statusCode !== 200)
        throw new Error("Error updating Subscription status");

      const parsedResponse = JSON.parse(response);
      console.log(parsedResponse);
      commit("SET_SUBSCRIPTION_STATUS", parsedResponse.status_id);
      console.log(parsedResponse);
    } catch ({ message }) {
      const messageToDisplay = message.replace("GraphQL error: ", "");
      commit("addNotification", {
        type: messages.DANGER,
        text: messageToDisplay,
      });
    }
  },
  resetSubscriptionStatus({ commit }) {
    try {
      commit("RESET_SUBSCRIPTION_STATUS");
    } catch ({ message }) {
      const messageToDisplay = message.replace("GraphQL error: ", "");
      commit("addNotification", {
        type: messages.DANGER,
        text: messageToDisplay,
      });
    }
  },
  async getCatalogByName({ commit, dispatch }, { name }) {
    try {
      const {
        data: { getCatalog },
      } = await apolloClient.query({
        query: catalogQuery,
        variables: {
          name,
        },
      });

      const { statusCode, message, response } = getCatalog;
      if (statusCode != 200)
        throw new Error(`Failed fetching ${name} data: ${message}`);
      commit("setCatalogByName", { name, response });
    } catch ({ message }) {
      const messageToDisplay = message.replace("GraphQL error: ", "");
      commit("addNotification", {
        type: messages.DANGER,
        text: messageToDisplay,
      });
    }
  },
  async newOrRenewal({ commit, dispatch }, { value }) {
    try {
      if (typeof value !== "number") throw new Error("Invalid TypeDef on Type");
      /* checa si es 1 o 2 comparando la constante */
      const isValidForm = NEW_OR_RENEWAL_CONSTANT.some(item => value == item);
      if (!isValidForm) throw new Error("Invalid Data on Type");
      commit("NEW_OR_RENEWAL", { value });
    } catch ({ message }) {
      const messageToDisplay = message.replace("GraphQL error: ", "");
      commit("addNotification", {
        type: messages.DANGER,
        text: messageToDisplay,
      });
    }
  },
  async getLastSubscription({ commit, dispatch }) {
    try {
      const {
        data: { getLastSubscription },
      } = await apolloClient.query({
        query: lastSubscriptionQuery,
      });
      console.log(getLastSubscription);
      const { statusCode, message, response } = getLastSubscription;
      if (statusCode != 200)
        throw new Error(`Failed fetching data: ${message}`);
      commit("setLastSubscription", { response });
    } catch ({ message }) {
      const messageToDisplay = message.replace("GraphQL error: ", "");
      commit("addNotification", {
        type: messages.DANGER,
        text: messageToDisplay,
      });
    }
  },

  async updateDataSubscription({ commit, dispatch, state }, { reference }) {
    try {
      const variables = { subscriptionId: state.subscription_id, reference };
      const {
        data: { updateSubscription },
      } = await apolloClient.mutate({
        mutation: UPDATE_SUBSCRIPTION,
        variables,
        fetchPolicy: "no-cache",
      });

      const { statusCode, message, response } = updateSubscription;
      if (statusCode != 200)
        throw new Error(`Failed fetching data: ${message}`);
      const parsedResponse = JSON.parse(response);
      const nameReference = parsedResponse.reference;
      commit("SET_SUBSCRIPTION_REFERENCE", { nameReference });
    } catch ({ message }) {
      const messageToDisplay = message.replace("GraphQL error: ", "");
      commit("addNotification", {
        type: messages.DANGER,
        text: messageToDisplay,
      });
    }
  },

  async registerIdSubscription({ commit, dispatch }) {
    try {
      const {
        data: { addSubscription },
      } = await apolloClient.mutate({
        mutation: ADD_SUBSCRIPTION,
        fetchPolicy: "no-cache",
      });

      const { statusCode, message, response } = addSubscription;
      if (statusCode != 200)
        throw new Error(`Failed fetching data: ${message}`);
      const parsedResponse = JSON.parse(response);
      const subscription_id = parsedResponse.id;
      commit("SET_SUBSCRIPTION_INFO", { subscription_id });
      setItemInStorage("subscriptionData", "subscriptionId", subscription_id);
    } catch ({ message }) {
      const messageToDisplay = message.replace("GraphQL error: ", "");
      commit("addNotification", {
        type: messages.DANGER,
        text: messageToDisplay,
      });
    }
  },
  async checkSubscriptionStored({ commit, dispatch, state, getters }) {
    try {
      /* temporal */
      if (
        $router.currentRoute.params &&
        $router.currentRoute.params.subscriptionId
      ) {
        const editID = $router.currentRoute.params.subscriptionId;
        setItemInStorage("subscriptionData", "subscriptionId", editID);
      } else {
        removeKeyFromStorage("subscriptionData", "subscriptionId");
        commit("RESET_SUBSCRIPTION_INFO");
      }
      const existsOnStorage = !!(
        searchObjectInStorage("subscriptionData") &&
        searchKeyInStorage("subscriptionData", "subscriptionId")
      );
      if (existsOnStorage) {
        commit("SET_SUBSCRIPTION_INFO", {
          subscription_id: parseInt(
            searchKeyInStorage("subscriptionData", "subscriptionId")
          ),
        });
        const { subscription_id } = state;
        const {
          data: { getSubscription },
        } = await apolloClient.query({
          query: subscriptionQuery,
          variables: {
            subscriptionId: subscription_id,
          },
          fetchPolicy: "no-cache",
        });

        const { statusCode, response, message, error } = getSubscription;

        if (statusCode !== 200)
          throw new Error(`msg: ${message} error: ${error}`);

        const { subscription, submission = false } = JSON.parse(response);

        if (submission) {
          await dispatch("newOrRenewal", { value: submission.type });

          commit("saveAccountInformation", {
            insuredName: submission.insured_name || "",
            name: submission.name || "",
            country: submission.catalog_country_id || "",
            typeOfRisk: submission.catalog_type_risk_id || "",
            activity: submission.catalog_activity_id || "",
            currency: submission.catalog_currency_id || "",
            broker: submission.broker || "",
            cedent: submission.cedent || "",
          });

          /* set de lo datos de contactos */
          commit("saveContactsInformation", submission.contacts || []);
        }

        commit("SET_SUBSCRIPTION_REFERENCE", {
          nameReference: subscription.reference,
        });

        commit("SET_SUBSCRIPTION_STATUS", subscription.status_id);
      }
    } catch (e) {
      console.error(e);
      const { message } = e;
      const messageToDisplay = message.replace("GraphQL error: ", "");
      commit("addNotification", {
        type: messages.DANGER,
        text: messageToDisplay,
      });
    }
  },
  async existsSubscription({ commit }, { id }) {
    try {
      const variables = { id };

      const { data } = await apolloClient.query({
        query: subscriptionExistsQuery,
        variables,
        fetchPolicy: "no-cache",
      });

      const { subscriptionExists } = data;
      const { statusCode } = subscriptionExists;

      if (statusCode !== 200) throw new Error("Subscription Not Found");
      return true;
    } catch (e) {
      console.error(e);
      return false;
    }
  },
  async closeAccountAction({ commit, state, dispatch }, { closeAccount }) {
    try {
      const { subscription_id } = state;
      const variables = {
        subscriptionId: subscription_id,
        reasonClosed: closeAccount.id,
        emailSendId: closeAccount.email,
      };
      const {
        data: { createCloseReason },
      } = await apolloClient.mutate({
        mutation: CREATE_CLOSE_REASON,
        variables,
        fetchPolicy: "no-cache",
      });

      const { statusCode, response, message, error } = createCloseReason;

      if (statusCode !== 200)
        throw new Error(`msg: ${message} error: ${error}`);

      await dispatch("resetSubscription");

      $router.push("/subscription/submission");

      commit("addNotification", {
        type: messages.SUCCESS,
        text: "Closed Account",
      });
    } catch ({ message }) {
      const messageToDisplay = message.replace("GraphQL error: ", "");
      commit("addNotification", {
        type: messages.DANGER,
        text: messageToDisplay,
      });
    }
  },
  async resetAccountInformation({ commit }) {
    commit("RESET_ACCOUNT_INFORMATION");
  },
  async resetCedentInformation({ commit }) {
    commit("RESET_CEDENT_INFORMATION");
  },
  async resetContactsInformation({ commit }) {
    commit("RESET_CONTACTS_INFORMATION");
  },
  async resetSubscription({ commit }) {
    commit("RESET_SUBSCRIPTION_INFO");
    commit("RESET_ACCOUNT_INFORMATION");
    commit("RESET_CEDENT_INFORMATION");
    commit("RESET_CONTACTS_INFORMATION");
    commit("RESET_SUBSCRIPTION_REFERENCE");
    /* agregar los resets de documentos si es necesario */
    removeKeyFromStorage("subscriptionData", "subscriptionId");
  },
  async getSubscriptionList({ commit }, payload) {
    try {
      const { limit, offset } = payload;
      // console.log('Entro:', limit, offset)
      const {
        data: { getSubscriptionList },
      } = await apolloClient.query({
        query: getAllSubscriptionQuery,
        variables: {
          limit,
          offset,
        },
        fetchPolicy: 'no-cache',
      });
      const { statusCode, message, response } = getSubscriptionList;
      if (statusCode != 200)
        throw new Error(`Failed fetching data: ${message}`);
      commit("setSubscriptionList", response);
      commit("setSubscriptionListPagination", payload);
    } catch ({ message }) {
      const messageToDisplay = message.replace("GraphQL error: ", "");
      commit("addNotification", {
        type: messages.DANGER,
        text: messageToDisplay,
      });
    }
  },
  async loadSubscription({ commit }, payload) {
    try {
      const { id, reference, catalog_type_risk_id } = payload;
      commit("SET_SUBSCRIPTION_INFO", { subscription_id: id });
      setItemInStorage("subscriptionData", "subscriptionId", id);
      commit("SET_SUBSCRIPTION_REFERENCE", { nameReference: reference });
    } catch ({ message }) {
      const messageToDisplay = message.replace("GraphQL error: ", "");
      commit("addNotification", {
        type: messages.DANGER,
        text: messageToDisplay,
      });
    }
  },
};
