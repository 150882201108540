<template>
	<div 
		v-if="show"
		class="
		Loading 
		d-flex
		justify-center
		align-center
		">
  		<img src="@/assets/img/loader.svg" />  
	</div>
</template>
<script>
export default {
	name:'Loading',
	props:{
		show:Boolean
	}
}
</script>
<style lang="less">
.Loading{
	width: 100vw;
	height: 100vh;
	position: fixed;
	left: 0;
	top: 0;
	background: rgba(0,0,0,.7);
	z-index: 5000;
}

</style>