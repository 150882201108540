/* api */
import { apolloClient } from "../../lib/api";

/* queries */
import getUsersList from "./queries/getUsersList";
import getUserByNameQuery from "./queries/getUserByName";

/* mutations */
import CREATE_SLU_USER_MUTATION from "./mutations/createSluUser";
import CREATE_SLU_USER_NEW_MUTATION from "./mutations/createUserNew";
import DELETE_SLU_USER from "./mutations/deleteUser";

/* contantes */
import messages from "../../constants/messages";

export default {
  async getAllUsers({ commit, dispatch }) {
    try {
      const {
        data: { getAllUsers },
      } = await apolloClient.query({
        query: allUserQuery,
      });
    } catch ({ message }) {
      const messageToDisplay = message.replace("GraphQL error: ", "");
      commit("addNotification", {
        type: messages.DANGER,
        text: messageToDisplay,
      });
    }
  },

  async getListUsers({ commit, dispatch }) {
    try {
      const { data } = await apolloClient.query({
        query: getUsersList,
      });
      commit('setUsersList', { users: data["getUsersList"] })
    } catch ({ message }) {
      console.log(message)
      const messageToDisplay = message.replace("GraphQL error: ", "");
      commit("addNotification", {
        type: messages.DANGER,
        text: messageToDisplay,
      });
    }
  },
  async deleteUser({ commit }, {id}) {
    console.log(id)
    const variables = {
      userId: id
    }
    try {
      const {
        data: { deleteUser },
      } = await apolloClient.mutate({
        mutation: DELETE_SLU_USER,
        variables,
        fetchPolicy: "no-cache",
      });
      console.log(deleteUser)
    }catch ({ message }) {
      const messageToDisplay = message.replace("GraphQL error: ", "");
      commit("addNotification", {
        type: messages.DANGER,
        text: messageToDisplay,
      });
    }
    
  },
  async createUserTest({ commit }, userData) {
    const variables = {
      ...userData
    }
    const {
      data: { createUserNew },
    } = await apolloClient.mutate({
      mutation: CREATE_SLU_USER_NEW_MUTATION,
      variables,
      fetchPolicy: "no-cache",
    });

  },
  async createUser({ commit }, { createUserData }) {
    try {
      commit('SAVE_CREATE_SLU_USER', createUserData)
      const variables = {
        role: createUserData.userRole,
        name: createUserData.userName,
        email: createUserData.userEmail,
        phone: createUserData.userPhone,
        company: createUserData.userCompany.id,
        country: createUserData.userCountry.id,
        address: createUserData.userAddress,
        lastName: createUserData.userLastName,
        signature: createUserData.userSignature,
      };
      const {
        data: { createSluUser },
      } = await apolloClient.mutate({
        mutation: CREATE_SLU_USER_MUTATION,
        variables,
        fetchPolicy: "no-cache",
      });

      const { statusCode, message } = createSluUser;
      if (statusCode !== 200) throw new Error(message);

      commit("addNotification", {
        type: messages.SUCCESS,
        text: "SLU User Created!",
      });

      commit("RESET_CREATE_SLU_USER");
    } catch ({ message }) {
      const messageToDisplay = message.replace("GraphQL error: ", "");
      commit("addNotification", {
        type: messages.DANGER,
        text: messageToDisplay,
      });
    }
  },
  async usersName({ commit, dispatch }, { name }) {
    try {
      const {
        data: { getUsersByName },
      } = await apolloClient.query({
        query: getUserByNameQuery,
        variables: {
          name,
        },
        fetchPolicy: "no-cache",
      });
      getUsersByName.forEach((value, index) => {
        value.value = value.name + " " + value.last_name;
      });

      commit("setMentionsObject", getUsersByName);
    } catch ({ message }) {
      const messageToDisplay = message.replace("GraphQL error: ", "");
      commit("addNotification", {
        type: messages.DANGER,
        text: messageToDisplay,
      });
    }
  },
};
