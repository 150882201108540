/* api */
import { apolloClient } from "../../lib/api"

/* mutaciones */
import CREATE_BROKER_MUTATION from "./mutations/createBroker"
import CREATE_CEDENT_MUTATION from "./mutations/createCedent"

/* queries */
import BROKERS_CEDENTS_QUERY from "./queries/getAllBrokersCedents"
import BROKERS_QUERY from "./queries/getBrokers"
import CEDENTS_QUERY from "./queries/getCedents"

/* constantes */
import messages from "../../constants/messages"

export default {
  /* (context) destructured */
  async createBroker({ commit, dispatch, state }, { brokerInformation }) {
    try {
      commit("SAVE_BROKER_INFORMATION", brokerInformation)

      const {
        brokerEmail,
        brokerName,
        brokerPhone,
        brokerCompany,
        brokerRole,
        brokerCountry,
        brokerAddress,
      } = brokerInformation

      const variables = {
        email: brokerEmail,
        name: brokerName,
        roleCompany: brokerRole,
        catalogCompanyId: brokerCompany.id,
        catalogCountryId: brokerCountry.id,
        phone: brokerPhone,
        address: brokerAddress,
      }

      const { data } = await apolloClient.mutate({
        mutation: CREATE_BROKER_MUTATION,
        variables,
        fetchPolicy: "no-cache",
      })

      const {
        createBroker: { statusCode, response, message, error },
      } = data

      if (statusCode !== 200) throw new Error(message)

      commit("addNotification", {
        type: messages.SUCCESS,
        text: "Broker created!",
      })

      commit("RESET_BROKER_INFORMATION")
    } catch ({ message }) {
      const messageToDisplay = message.replace("GraphQL error: ", "")
      commit("addNotification", {
        type: messages.DANGER,
        text: messageToDisplay,
      })
    }
  },
  async createCedent({ commit, dispatch, state }, { cedentData }) {
    try {
      commit("SAVE_CEDENT_DATA", cedentData)

      const {
        cedentEmail,
        cedentName,
        cedentPhone,
        cedentCompany,
        cedentRole,
        cedentCountry,
        cedentAddress,
      } = cedentData

      const variables = {
        email: cedentEmail,
        name: cedentName,
        roleCompany: cedentRole,
        catalogCompanyId: cedentCompany.id,
        catalogCountryId: cedentCountry.id,
        phone: cedentPhone,
        address: cedentAddress,
      }

      const { data } = await apolloClient.mutate({
        mutation: CREATE_CEDENT_MUTATION,
        variables,
        fetchPolicy: "no-cache",
      })

      const {
        createCedent: { statusCode, response, message, error },
      } = data

      if (statusCode !== 200) throw new Error(message)

      commit("addNotification", {
        type: messages.SUCCESS,
        text: "Cedent created!",
      })

      commit("RESET_CEDENT_DATA")
    } catch ({ message }) {
      const messageToDisplay = message.replace("GraphQL error: ", "")
      commit("addNotification", {
        type: messages.DANGER,
        text: messageToDisplay,
      })
    }
  },
  async getBrokers({ commit }) {
    try {
      const config = { response: 'getAllBrokers' }
      const prefetch = await apolloClient.query({ query: BROKERS_QUERY, fetchPolicy: 'no-cache' })
      const data = prefetch['data'][config.response]

      const { statusCode, response, message, error } = data
      if (statusCode !== 200) throw new Error(message || error)

      const parsedResponse = JSON.parse(response)
      commit("SET_AVAILABLE_BROKERS", { brokers: Array.from(parsedResponse) })
    } catch ({ message }) {
      const messageToDisplay = message.replace("GraphQL error: ", "")
      commit("addNotification", {
        type: messages.DANGER,
        text: messageToDisplay,
      })
    }
  },
  async getCedents({ commit }) {
    try {
      const config = { response: 'getAllCedents' }
      const prefetch = await apolloClient.query({ query: CEDENTS_QUERY, fetchPolicy: 'no-cache' })
      const data = prefetch['data'][config.response]

      const { statusCode, response, message, error } = data
      if (statusCode !== 200) throw new Error(message || error)

      const parsedResponse = JSON.parse(response)
      commit("SET_AVAILABLE_CEDENTS", { cedents: Array.from(parsedResponse) })
    } catch ({ message }) {
      const messageToDisplay = message.replace("GraphQL error: ", "")
      commit("addNotification", {
        type: messages.DANGER,
        text: messageToDisplay,
      })
    }
  },
  async getBrokersAndCedents({ commit, dispatch }) {
    try {
      const {
        data: { getAllBrokersCedents },
      } = await apolloClient.query({
        query: BROKERS_CEDENTS_QUERY,
        fetchPolicy: "no-cache",
      })

      const { statusCode, response, message, error } = getAllBrokersCedents

      if (statusCode !== 200) throw new Error(message)

      const brokerCedentData = JSON.parse(response)

      commit("SET_AVAILABLE_BROKERS", { brokers: brokerCedentData.brokers })
      commit("SET_AVAILABLE_CEDENTS", { cedents: brokerCedentData.cedents })
    } catch ({ message }) {
      const messageToDisplay = message.replace("GraphQL error: ", "")
      commit("addNotification", {
        type: messages.DANGER,
        text: messageToDisplay,
      })
    }
  },
  async resetBrokerInformation({ commit }) {
    try {
      commit("RESET_BROKER_INFORMATION")
    } catch ({ message }) {
      const messageToDisplay = message.replace("GraphQL error: ", "")
      commit("addNotification", {
        type: messages.DANGER,
        text: messageToDisplay,
      })
    }
  },
  async resetCedentData({ commit }) {
    try {
      commit("RESET_CEDENT_DATA")
    } catch ({ message }) {
      const messageToDisplay = message.replace("GraphQL error: ", "")
      commit("addNotification", {
        type: messages.DANGER,
        text: messageToDisplay,
      })
    }
  },
}
