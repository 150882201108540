import auth from "./auth";
import errors from "./errors";
import ui from "./ui";
import subscription from "./subscription";
import create from "./create";
import outlook from "./outlook";
import closeAccount from "./close_account";
import emails from "./emails";
import contacts from "./contacts";
import quotation from './quotation'

export default {
  auth,
  ui,
  ...create,
  ...errors,
  ...subscription,
  ...closeAccount,
  ...emails,
  ...contacts,
  ...quotation,
  outlook,
};
