import ui from "./ui";
import auth from "./auth";
import email from "./email";
import errors from "./errors";
import create from "./create";
import contacts from "./contacts";
import toBeDefined from "./toBeDefined";
import subscription from "./subscription";

export default {
  ...auth,
  ...ui,
  ...errors,
  ...subscription,
  ...create,
  ...email,
  ...contacts,
  ...toBeDefined,
};
